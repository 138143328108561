import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateMapDataPoints,
  sendRequestForUpdateOrderComment,
  fetchCompliteOrders
} from '../../../../../redux/reducers/utils/deliveryReducers/deliveryMapReducer';
import styled from 'styled-components';
import { ReactComponent as IconCash } from '../../../../../assets/icons/icon-сash.svg';
import { ReactComponent as IconMobile } from '../../../../../assets/icons/icon-mobile.svg';
import { ReactComponent as IconPlacemarkAppointed } from '../../../../../assets/icons/icon-order-status-appointed.svg';
import { ReactComponent as IconPlacemarkDelivered } from '../../../../../assets/icons/icon-order-status-complited.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as IconOK } from '../../../../../assets/icons/icon-success.svg';
import { Button, Popconfirm } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Tooltip from '../../../../../ui/Tooltip';

const OrderCard = ({ selectedOrder, order, index }) => {
  const {
    updateOrderCommentStatus,
  } = useSelector((store) => store.deliveryMap);
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef(null);

  const handleEditCommentActivate = () => {
    setIsEditing(true);
  };

  const handleEditCommentSubmit = () => {
    const updatedOrders = selectedOrder.orders.map((order, i) =>
      i === index ? { ...order, comment: comment } : order
    );

    const updatedPoints = [
      {
        id: selectedOrder.addressId,
        orders: updatedOrders,
      },
    ];

    const requestData = {
      token: localStorage.getItem('token'),
      orderId: order.id,
      comment: comment,
    };

    dispatch(updateMapDataPoints(updatedPoints));
    dispatch(sendRequestForUpdateOrderComment(requestData));
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleCompliteDelivery = () => {
    const allOrderIds = [order.id];

    console.log(order, allOrderIds);
    const requestData = {
      token: localStorage.getItem('token'),
      orderIds: allOrderIds,
    }

    dispatch(fetchCompliteOrders(requestData));
  };

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.select();
    }
  }, [isEditing]);

  useEffect(() => {
    setComment('');
    setIsEditing(false);
  }, [selectedOrder]);

  useEffect(() => {
    if (updateOrderCommentStatus === 'resolved') {
      console.log('Комментарий успешно обновлен!');
    }
  }, [updateOrderCommentStatus, dispatch]);

  return (
    <StyledOrderCard>
      <StyledRow>
        <StyledOrderQueue>{index + 1}</StyledOrderQueue>
        <StyledUser>{order.user.name} {order.user.phone}</StyledUser>

        <Tooltip title="Завершить доставку заказа">
          <Popconfirm
            title="Завершение доставки заказа!"
            description={'Вы уверены что хотите завершить доставку заказа?'}
            okText="Да"
            cancelText="Нет"
            placement="top"
            onConfirm={handleCompliteDelivery}
          >
            <Button type='text' danger={true} icon={<CheckOutlined/>}/>
          </Popconfirm>
        </Tooltip>
      </StyledRow>

      <StyledRow>
        <div style={{fontSize: '10px', color: '#999999'}}>ID: {order.id} <span style={{color: order.status.id === 50 ? '#00ab0b' : '#000000'}}>{order.status.name}</span></div>
      </StyledRow>
      <StyledRow>
        <StyledPayment><IconCash /> {order.payment.finalPrice} ₽</StyledPayment> •
        <StyledKitchen>{order.kitchen.name}</StyledKitchen>
      </StyledRow>
      <StyledDelivery><IconMobile /> {order.delivery.day}</StyledDelivery>
      <StyledComment>
        {!isEditing ?
          <>
            <StyledCommentValue>{order.comment}</StyledCommentValue>
            <StyledChangeCommentButton onClick={handleEditCommentActivate}><IconEdit /></StyledChangeCommentButton>
          </>
          :
          <>
            <StyledCommentInput onChange={handleCommentChange} ref={inputRef} disabled={!isEditing} />
            <StyledChangeCommentButton onClick={handleEditCommentSubmit}><IconOK /></StyledChangeCommentButton>
          </>
        }
      </StyledComment>
      <StyledStatus>
        {order.courier.id && <StyledCourier style={{ borderColor: order.courier.color }}>{order.courier.name}</StyledCourier>}
        {order.status.id === 50 ?
          <IconPlacemarkDelivered style={{ fill: order.courier.color }} />
        :
          <IconPlacemarkAppointed style={{ stroke: order.courier.color }} />
        }
      </StyledStatus>
    </StyledOrderCard>
  );
};

export default OrderCard;

const StyledOrderCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  height: auto;
  font-size: 13px;
  transition: all .3s;
`;
const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`
const StyledOrderQueue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #3498DB;
`
const StyledUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: #3498DB;
`
const StyledPayment = styled.div`

`
const StyledKitchen = styled.div`

`
const StyledDelivery = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const StyledComment = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  background: #ffffff;
  border-radius: 5px;
`;
const StyledCommentValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  width: 100%;
  height: 100%;
  background: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
`;
const StyledCommentInput = styled.input`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  width: 100%;
  height: 100%;
  background: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  outline: none;
`;
const StyledChangeCommentButton = styled.div`
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 100%;
  background: #ffffff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: all .3s;
  cursor: pointer;
  &:hover {
    background: #999999;
  }
`;
const StyledAddCommentButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  text-decoration: underline;
  transition: all .3s;
  cursor: pointer;
  &:hover {
    color: #1d95f8;
  }
`;
const StyledStatus = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledCourier = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  width: auto;
  height: 20px;
  font-size: 13px;
  border: 1px solid;
  border-radius: 20px;
`;
