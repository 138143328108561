import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../../components/Loader';
import StatisticsComponent from './components/StatisticsComponent';
import DaysStatistics from './components/DaysStatistics';
import KitchenSelectorComponent from '../../components/KitchenSelectorComponent';
import { Container, Page, ScrolledCol, Title, VerticalSpace } from '../../../../core/styles';
import NewsComponent from './components/NewsComponent';
import NewKitchenCreateComponent from './components/NewKitchenCreateComponent';
import MainStats from './components/MainStats';
import NewKitchenSteps from './components/NewKitchenSteps';
import ListProductCard from './components/ListProductCard';
import styled from 'styled-components';
import WelcomeComponent from './components/WelcomeComponent';
import { Link } from 'react-router-dom';
import { SettingsIcon } from '../../../../components/Icons';
import Flex from '../../../../components/Flex';
import ListReviewsCard from './components/ListReviewsCard';
import TopProductsGrid from './components/TopProductsGrid';
import { sendRequestKitchens } from '../../../../redux/reducers/utils/kitchenReducers/kitchenReducer';
import { sendRequestCitiesGet } from '../../../../redux/reducers/utils/settingsReducers/settingsCatalogsReducer';
import { getAllKitchenStatuses } from '../../../../redux/reducers/utils/kitchenReducers/kitchenSettingsReducer';

const DashboardPage = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const {kitchensRequestStatus, kitchens, selectKitchen} = useSelector((store) => store.kitchen);
	const dispatch = useDispatch();
  const productCardRef = useRef(null);
  const [highlighted, setHighlighted] = useState(false);

  useEffect(() => {
		if (isAuth) {
			dispatch(sendRequestKitchens({ token: localStorage.getItem('token'), offset: 0, limit: 10000 }));
			dispatch(sendRequestCitiesGet({ token: localStorage.getItem('token') }));
			dispatch(getAllKitchenStatuses({ token: localStorage.getItem('token') }));
		}
	}, [isAuth]);

  useEffect(() => {
    if (highlighted) {
      const timer = setTimeout(() => setHighlighted(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [highlighted]);

  const handleStepCardClick = () => {
    if (productCardRef.current) {
      productCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setHighlighted(true);
    }
  }

  return (
    <Page>
			<ScrolledCol>
				<Container>
					<VerticalSpace>

            {kitchensRequestStatus === 'pending' ?
              <Loader/>
            :
              <>
                {kitchens && kitchens.length === 0 ?
                  <WelcomeComponent/>
                :
                  <>
                    <Header>
                      <HeaderMainCol>
                        <KitchensBlock>
                          <div>
                            <StyledLink to={'/kitchen/settings'}><SettingsIcon width='16px' height='16px'/> Настройки</StyledLink>
                            <KitchenSelectorComponent/>
                          </div>
                          <NewKitchenCreateComponent/>
                        </KitchensBlock>
                        {/* <MainStats/> */}
                      </HeaderMainCol>

                      {/* <HeaderSecondCol>
                        <NewsComponent carousel={true}/>
                      </HeaderSecondCol> */}
                    </Header>

                    <Content>
                      { selectKitchen &&
                        selectKitchen.stepsStartKitchen && (
                        selectKitchen.stepsStartKitchen.concludeContract === 0 ||
                        selectKitchen.stepsStartKitchen.fillListProducts === 0 ||
                        selectKitchen.stepsStartKitchen.dishesAndBagsBeenPurchased === 0 ||
                        selectKitchen.stepsStartKitchen.testRunBeen === 0 ) &&
                          <NewKitchenSteps onClick={handleStepCardClick}/>
                      }

                      <SectionMenu>
                        <SectionMenuSecondCol>
                          <ListProductCard highlighted={highlighted} ref={productCardRef}/>
                        </SectionMenuSecondCol>

                        <SectionMenuMainCol>
                          <DaysStatistics/>
                        </SectionMenuMainCol>
                      </SectionMenu>
                      
                      <SectionCharts>
                        <SectionChartsMainCol>
                          <StatisticsComponent/>
                          <TopProductsGrid/>
                        </SectionChartsMainCol>

                        <SectionChartsSecondCol>
                          <ListReviewsCard/>
                        </SectionChartsSecondCol>
                      </SectionCharts>
                    </Content>
                  </>
                }
              </>
            }

          </VerticalSpace>
				</Container>
			</ScrolledCol>
		</Page>
  );
};

export default DashboardPage;

const Header = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 50px;
`
const HeaderMainCol = styled.div`
  flex: 3 1 990px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
`
const KitchensBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
`
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  color: var(--color-text);
  &:hover {
    color: var(--color-accent);
  }
`
const HeaderSecondCol = styled.div`
  flex: 1 0 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  max-width: 100%;
	min-width: 0;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
const SectionMenu = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
`
const SectionMenuSecondCol = styled.div`
  flex: 1 0 330px;
  display: flex;
  flex-direction: column;

`
const SectionMenuMainCol = styled.div`
  flex: 5 1 500px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
	min-width: 0;
`
const SectionCharts = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;
`
const SectionChartsMainCol = styled.div`
  flex: 3 1 750px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 750px) {
    flex: 1;
  }
`
const SectionChartsSecondCol = styled.div`
  flex: 2 0 500px;
  display: flex;
  flex-direction: column;
  @media (max-width: 500px) {
    flex: 1;
  }
`