import React, { useState, useEffect } from "react";
import { Typography, Table, Button, Form, Input, InputNumber, Tabs, Drawer, Flex, Select, DatePicker, Spin, Switch, Slider } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { formatDate } from "../../../../../core/utils";
dayjs.extend(customParseFormat);

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const WalletDrawerComponent = ({ isDrawerOpen, onClose, record, corpclientsOptions, clients, addresses, transactions, totalTransactions, currentTransactionsPage, onUpdateWallet, onSearchClients, onSearchAddresses, onSearchTransaction, onCreateTransaction, onChange }) => {
  const [formCreateTransaction] = Form.useForm();
  const [formUpdateWallet] = Form.useForm();
  const [formFilterTransaction] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [amount, setAmount] = useState([0, 100000]);
  const [searchClientsResults, setSearchClientsResults] = useState([]);
  const [isClientsFetching, setIsClientsFetching] = useState(false);
  const [searchAddressesResults, setSearchAddressesResults] = useState([]);
  const [isAddressesFetching, setIsAddressesFetching] = useState(false);

  useEffect(() => {
    setSearchClientsResults(clients);
    setIsClientsFetching(false);
  }, [clients]);

  useEffect(() => {
    setSearchAddressesResults(addresses);
    setIsAddressesFetching(false);
  }, [addresses]);

  useEffect(() => {
    if (record) {
      setIsFormChanged(false);
      formUpdateWallet.setFieldsValue({
        status: record.status === 'active' ? true : false,
        nameWallet: record.name,
        limitAmountPerDay: record.limitAmountPerDay,
        maxPercPay: record.maxPercPay,
        corporatContractId: record.wallets_corporates !== null ? record.wallets_corporates.corporatContractId : 0,
        walletsUsers: record.wallets_users.map(user => {return {value: user?.id, label: `${user?.name} (${user?.phone})`}}),
        walletsAddresses: record.wallets_addresses.map(address => {return {value: address?.id, label: `ID: ${address?.id}, г.${address?.citiName}, ул.${address?.street}, д.${address?.house}, кв.${address?.flat}, э.${address?.floor}, вх.${address?.entrance}, корп.${address?.structure}`}}),
        valid: record.validFrom && record.validTo && [dayjs(record.validFrom), dayjs(record.validTo)],
      });
    }
  }, [record, formUpdateWallet]);

  const handleCloseDrawer = () => { onClose(false); };
  const handleFormChange = () => { setIsFormChanged(true); };

  const handleUpdateWallet = () => {
    formUpdateWallet.validateFields()
    .then((values) => {
      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== '')
      );

      if (filteredValues.valid) {
        const [validFrom, validTo] = filteredValues.valid;
        filteredValues.validFrom = validFrom.format(serverFormat);
        filteredValues.validTo = validTo.format(serverFormat);
        delete filteredValues.valid;
      }

      if (filteredValues.status === true) {
        filteredValues.status = 'active'
      } else {
        filteredValues.status = 'deleted'
      }

      if (filteredValues.corporatContractId === null) {
        delete filteredValues.corporatContractId;
      }

      if (filteredValues.walletsUsers.length) {
        const userValues = filteredValues.walletsUsers.map(user => {
          if (typeof user === 'object') {
            return user.value;
          } else {
            return user;
          }
        });

        filteredValues.walletsUsers = userValues
      }

      if (filteredValues.walletsAddresses.length) {
        const addressValues = filteredValues.walletsAddresses.map(address => {
          if (typeof address === 'object') {
            return address.value;
          } else {
            return address;
          }
        });

        filteredValues.walletsAddresses = addressValues
      }

      onUpdateWallet({...filteredValues, walletId: record.walletId});
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  };

  const handleSearchClients = (value) => {
    if (value.length < 12) {
      setSearchClientsResults([]);
      return;
    }

    setIsClientsFetching(true);
    onSearchClients(value)
  }

  const handleSearchAddresses = (value) => {
    if (/^\d+$/.test(value)) {
      setIsAddressesFetching(true);
      onSearchAddresses(value)
    }
  }

  const handleValidateAddress = (e) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
      e.preventDefault();
    }
  };

  const onMinInputChange = (value) => { setAmount([value, amount[1]]); };
  const onMaxInputChange = (value) => { setAmount([amount[0], value]); };

  const handleSearchTransaction = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== '')
    );
  
    if (amount) {
      const [amountFrom, amountTo] = amount;
      filteredValues.amountFrom = amountFrom;
      filteredValues.amountTo = amountTo;
      delete filteredValues.amount;
    }
  
    if (filteredValues.dateRange) {
      const [dateRangeFrom, dateRangeTo] = filteredValues.dateRange;
      filteredValues.dateRangeFrom = dateRangeFrom.format(serverFormat);
      filteredValues.dateRangeTo = dateRangeTo.format(serverFormat);
      delete filteredValues.dateRange;
    }

    onSearchTransaction({...filteredValues, walletId: record.walletId});
  }

  const handleCreateTransaction = (walletId, values) => {
    onCreateTransaction({ walletTo: walletId, ...values });
    formCreateTransaction.resetFields();
  };

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  return (
    <Drawer
      title={
        <Flex gap={20}>
          {isFormChanged &&
            <>
              <Button type="primary" onClick={handleUpdateWallet}>Сохранить</Button>
              <Button onClick={handleCloseDrawer}>Отменить</Button>
            </>
          }
        </Flex>
      }
      placement="right"
      mask={false}
      width='40%'
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
    >
      {record && 
      <>
        <Flex style={{marginBottom: '10px'}} justify='space-between' gap={16}>
          <Title level={4}>{record.name}: <span style={{color: '#25e000'}}>{record.amount}</span> баллов</Title>
        </Flex>
        
        <Form
          form={formCreateTransaction}
          layout="inline"
          onFinish={values => handleCreateTransaction(record.walletId, values)}
          style={{ marginBottom: 16 }}
        >
          <Flex style={{width: '100%'}} justify='flex-end' wrap='wrap' gap={16}>
            <Form.Item style={{flex: '0 0 auto'}} name="amount" rules={[{ required: true, message: 'Введите сумму' }]}>
              <InputNumber placeholder="Сумма" />
            </Form.Item>
            <Form.Item style={{flex: '1 1 320px'}} name="comment" rules={[{ required: true, message: 'Введите комментарий' }]}>
              <Input placeholder="Комментарий" />
            </Form.Item>
            <Form.Item style={{flex: '0 0 auto'}}>
              <Button type="primary" htmlType="submit">
                Добавить транзакцию
              </Button>
            </Form.Item>
          </Flex>
        </Form>

        <Tabs defaultActiveKey="1">
          <TabPane tab="Параметры" key="1">
            <Form
              form={formUpdateWallet}
              layout="vertical"
              onValuesChange={handleFormChange}
            >
              <Flex justify="flex-end">
                <Form.Item
                  style={{ margin: '0'}}
                  name="status"
                  valuePropName="checked"
                >
                  <Switch/>
                </Form.Item>
              </Flex>
              <Form.Item
                style={{ margin: '5px'}}
                name="nameWallet"
                label="Название кошелька"
                rules={[{ required: true, message: 'Пожалуйста, введите название кошелька' }]}
              >
                <Input placeholder='Введите название'/>
              </Form.Item>
              <Flex style={{width: '100%'}} gap={16}>
                <Form.Item
                  style={{ margin: '5px'}}
                  name="limitAmountPerDay"
                  label="Лимит расходов в день"
                >
                  <InputNumber style={{ width: '100%' }} placeholder="Укажите лимит"/>
                </Form.Item>
                <Form.Item
                  style={{ margin: '5px'}}
                  name="maxPercPay"
                  label="Лимит как % от заказа"
                >
                  <InputNumber style={{ width: '100%' }} placeholder="Укажите % лимита" min={0} max={100} formatter={value => `${value}%`}/>
                </Form.Item>
              </Flex>
              <Form.Item
                style={{ margin: '5px'}}
                name="corporatContractId"
                label="Договор с корп.клиентом"
              >
                <Select
                  showSearch
                  placeholder="Выберите договор"
                  optionFilterProp="children"
                  options={corpclientsOptions}
                  filterOption={(input, option) =>
                    (option?.contractNumber?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.contractDate?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.corporatContractId?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  // optionRender={(option) => (
                  //   <Flex vertical >
                  //     <Title level={5}>{option.data.contractNumber} от {option.data.contractDate}</Title>
                  //     <Flex gap='small'>
                  //       <Text type="secondary" size="small">ID: {option.data.corporatContractId}</Text>
                  //     </Flex>
                  //   </Flex>
                  // )}
                />
              </Form.Item>
              <Form.Item
                style={{ margin: '5px'}}
                name="walletsUsers"
                label="Доступен пользователям"
              >
                <Select
                  mode='multiple'
                  placeholder="Введите номера телефона"
                  onSearch={handleSearchClients}
                  notFoundContent={isClientsFetching ? <Spin size="small" /> : null}
                  filterOption={false}
                >
                  {searchClientsResults.map((phone) => (
                    <Option key={phone.userId} value={phone.userId}>
                      {phone.userName === null ? 'null' : phone.userName} ({phone.userPhone})
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ margin: '5px'}}
                name="walletsAddresses"
                label="Ограничение по адресу"
              >
                <Select
                  mode='multiple'
                  placeholder="Введите адреса (ID)"
                  onSearch={handleSearchAddresses}
                  onInputKeyDown={handleValidateAddress}
                  notFoundContent={isAddressesFetching ? <Spin size="small" /> : null}
                  filterOption={false}
                >
                  {searchAddressesResults.map((address) => (
                    <Option key={address.id} value={address.id}>
                      <div>
                        {address.id && <Text>ID: {address.id}, </Text>} 
                        {address.citiName && <Text>г.{address.citiName}, </Text>} 
                        {address.street && <Text>ул.{address.street}, </Text>} 
                        {address.house && <Text>д.{address.house}, </Text>} 
                        {address.flat && <Text>кв.{address.flat}, </Text>} 
                        {address.floor && <Text>э.{address.floor}, </Text>} 
                        {address.entrance && <Text>вх.{address.entrance}, </Text>} 
                        {address.structure && <Text>корп.{address.structure}, </Text>} 
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{ margin: '5px'}}
                name="valid"
                label="Время действия кошелька"
              >
                <RangePicker style={{ width: '100%'}} format={clientFormat}/>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Транзакции" key="2">
            <Form
              form={formFilterTransaction}
              layout='vertical'
              onFinish={values => handleSearchTransaction(values)}
              style={{ marginBottom: 16 }}
            >
              <Flex gap={16}>
                <Form.Item style={{ flex: '4', margin: '5px' }} name="amount" label="Диапазон сумм">
                  <Flex gap={10}>
                    <InputNumber
                      min={0}
                      max={1000}
                      value={amount[0]}
                      onChange={onMinInputChange}
                      style={{ width: '100%' }}
                    /> - 
                    <InputNumber
                      min={0}
                      max={100000}
                      value={amount[1]}
                      onChange={onMaxInputChange}
                      style={{ width: '100%' }}
                    />
                  </Flex>
                </Form.Item>
                <Form.Item style={{flex: '6', margin: '5px'}} name="dateRange" label="Создание транзакции">
                  <RangePicker style={{width: '100%'}} format={clientFormat}/>
                </Form.Item>
              </Flex>
              <Flex gap={16}>
                <Form.Item style={{flex: '4', margin: '5px'}} name="orderGroupId" label="Номер заказа">
                  <Input placeholder="Введите номер заказа" />
                </Form.Item>
                <Form.Item style={{flex: '6', margin: '5px'}} name="comment" label="Комментарий">
                  <Input placeholder="По комментарию" />
                </Form.Item>
              </Flex>
              <Flex style={{marginTop: '20px'}} justify='space-between' gap={16}>
                <Text type='secondary'>Найдено транзакций: {new Intl.NumberFormat().format(totalTransactions)}</Text>
                <Flex gap={16}>
                  <Button type='primary' htmlType='submit'>Поиск</Button>
                  <Button htmlType='reset'>Сбросить</Button>
                </Flex>
              </Flex>
            </Form>
            <Table
              size='small'
              dataSource={transactions}
              columns={[
                {
                  title: 'Дата и время', 
                  key: 'created',
                  render: (record) => (<Text>{formatDate(record.created)}</Text>),
                },
                {
                  title: 'Сумма', 
                  key: 'amount',
                  render: (record) => (
                    <Text style={{color: record.walletFrom ? '#ff0000' : record.walletTo ? '#249224' : undefined}}>{record.walletFrom ? '-' : record.walletTo ? '+' : undefined}{record.amount}</Text>
                  ),
                },
                {
                  title: 'Комментарий', 
                  dataIndex: 'comment', 
                  key: 'comment'
                },
              ]}
              pagination={{
                current: currentTransactionsPage,
                total: totalTransactions,
                pageSize: 20,
                showSizeChanger: false,
                showQuickJumper: true,
              }}
              onChange={handleTableChange}
            />
          </TabPane>
        </Tabs>
      </>
      }
    </Drawer>
  );
};

export default WalletDrawerComponent;
