import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { InputNumber as AntdInputNumber } from 'antd';

const InputNumber = ({
    style,
    className,
    placeholder,
    min,
    max,
    initialValue = 0,
    formatter,
    prefix,
    suffix,
    error,
    width,
    onClick,
    onChange,
    onEnter,
}) => {
    const [localValue, setLocalValue] = useState(initialValue);
    const [previousValue, setPreviousValue] = useState(initialValue);

    useEffect(() => {
        setLocalValue(initialValue);
    }, [initialValue]);

    const parseValue = (value) => {
        return value?.replace(/[^\d.-]/g, '') || '';
    };

    const handleValueChange = (newValue) => {
        const parsedValue = parseValue(String(newValue));
        setLocalValue(parsedValue);
        if (onChange) {
            onChange(parsedValue);
        }
    };

    const handleValueSubmit = () => {
        if (localValue !== previousValue) {
            setPreviousValue(localValue);
            if (onEnter) {
                onEnter(localValue);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleValueSubmit();
            e.target.blur();
        }
    };

    const handleBlur = () => {
        handleValueSubmit();
    };

    return (
        <StyledInputNumber
            style={style}
            className={className}
            inputMode="numeric"
            placeholder={placeholder}
            min={min}
            max={max}
            value={localValue}
            formatter={formatter}
            prefix={prefix}
            suffix={suffix}
            error={error}
            width={width}
            onClick={onClick}
            onChange={handleValueChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
        />
    );
};

export default InputNumber;

const StyledInputNumber = styled(AntdInputNumber)`
    display: flex;
    align-items: center;
    height: 40px;
    max-width: ${({ width }) => (width ? width : 'auto')};
    border: ${({ error }) => (error && 'solid 2px red')};
    transition: border 0s;
    &:hover,
    &:focus-within {
        border: solid 2px black !important;
    }
    .ant-input-number-input {
        &::placeholder {
            color: black;
            opacity: 1;
        }
    }
`;
