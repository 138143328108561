import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";
import apiInstance from '../../../../api';
import { selectCommonState } from '../commonReducer';

export const getAllProducts = createAsyncThunk(
  'kitchenMenu/getAllProducts',
  async (data, { getState, rejectWithValue }) => {
    const api = '/menu/products?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data.products;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const addMenu = createAsyncThunk(
  'kitchenMenu/addMenu',
  async (data, { getState, rejectWithValue }) => {
    const api = '/menu/addMenu?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

export const toggleMenuStatus = createAsyncThunk(
  'kitchenMenu/toggleMenuStatus',
  async (data, { getState, rejectWithValue }) => {
    const isEnabled = data.enabled
    const api = isEnabled ? '/menu/disableMenu?XDEBUG_SESSION_START=MyVSCode' : '/menu/enableMenu?XDEBUG_SESSION_START=MyVSCode';
    const state = getState();
    const commonState = selectCommonState(state);
    const fullApi = commonState.apiUrl + api;

    try {
      const response = await apiInstance.post(fullApi, JSON.stringify({ data }));

      if (response.data.result) {
        return response.data.data;
      } else {
        return rejectWithValue('Failed to fetch data');
      }
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
      return rejectWithValue(error?.response?.data?.message || 'Something went wrong');
    }
  }
);

const initialState = {
  //Menu
  allProductsGetRequestStatus: null,
  addMenuRequestStatus: null,
  toggleMenuRequestStatus: null,
  allProducts: [],
  currentRace: null,

  //Modifier Groups
  modifierGroupsGetRequestStatus: null,
  modifierGroups: [],
  currentModifierGroupId: null,
  modifierGroupsCreateRequestStatus: null,
  modifierGroupsUpdateRequestStatus: null,
  modifierGroupsDeleteRequestStatus: null,

  //Modifier Groups Products
  modifierGroupsProductsGetRequestStatus: null,
  modifierGroupsProducts: [],
  modifierGroupsProductCreateRequestStatus: null,
  modifierGroupsProductUpdateRequestStatus: null,
  modifierGroupsProductDeleteRequestStatus: null,
};

const kitchenMenuSlice = createSlice({
  name: 'kitchenMenu',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.allProductsGetRequestStatus = 'pending';
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.allProductsGetRequestStatus = 'fulfilled';
        state.allProducts = action.payload;
      })
      .addCase(getAllProducts.rejected, (state) => {
        state.allProductsGetRequestStatus = 'rejected';
      })
      .addCase(addMenu.pending, (state) => {
        state.addMenuRequestStatus = 'pending';
      })
      .addCase(addMenu.fulfilled, (state, action) => {
        state.addMenuRequestStatus = 'fulfilled';
        state.currentRace = {
          ...state.currentRace,
          menu: [{id: action.payload.menus.days[0].races[0].menuId}]
        };
      })
      .addCase(addMenu.rejected, (state) => {
        state.addMenuRequestStatus = 'rejected';
      })
      .addCase(toggleMenuStatus.pending, (state) => {
        state.toggleMenuRequestStatus = 'pending';
      })
      .addCase(toggleMenuStatus.fulfilled, (state) => {
        state.toggleMenuRequestStatus = 'fulfilled';
      })
      .addCase(toggleMenuStatus.rejected, (state) => {
        state.toggleMenuRequestStatus = 'rejected';
      })
      .addCase(PURGE, () => {
        return initialState;
      });
  },
  reducers: {
    //Menu
    updateAllProducts: (state, action) => { state.allProducts = action.payload; },
    updateProducts: (state, action) => {
      const updatedProducts = action.payload;
      state.allProducts = state.allProducts.map((product) => {
          const updatedProduct = updatedProducts.find(
              (updated) => updated.productId === product.productId
          );

          return updatedProduct ? { ...product, ...updatedProduct } : product;
      });
    },
    setCurrentRace: (state, action) => { state.currentRace = action.payload; },
    updateCurrentRace(state, action) {
      state.currentRace = {
        ...state.currentRace,
        ...action.payload
      };
    },

    //Modifier Groups
    sendRequestModifierGroupsGet: (state) => { state.modifierGroupsGetRequestStatus = 'pending'; },
    sendRequestModifierGroupsGetSuccess: (state) => { state.modifierGroupsGetRequestStatus = 'resolved'; },
    sendRequestModifierGroupsGetError: (state) => { state.modifierGroupsGetRequestStatus = 'rejected'; },
    clearModifierGroupsGetStatus: (state) => { state.modifierGroupsGetRequestStatus = null; },
    setModifierGroups: (state, action) => {
      state.modifierGroups = action.payload;
    },
    setCurrentModifierGroupId: (state, action) => {
      state.currentModifierGroupId = action.payload;
    },
    sendRequestModifierGroupsCreate: (state) => { state.modifierGroupsCreateRequestStatus = 'pending'; },
    sendRequestModifierGroupsCreateSuccess: (state) => { state.modifierGroupsCreateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsCreateError: (state) => { state.modifierGroupsCreateRequestStatus = 'rejected'; },
    clearModifierGroupsCreateStatus: (state) => { state.modifierGroupsCreateRequestStatus = null; },
    sendRequestModifierGroupsUpdate: (state) => { state.modifierGroupsUpdateRequestStatus = 'pending'; },
    sendRequestModifierGroupsUpdateSuccess: (state) => { state.modifierGroupsUpdateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsUpdateError: (state) => { state.modifierGroupsUpdateRequestStatus = 'rejected'; },
    clearModifierGroupsUpdateStatus: (state) => { state.modifierGroupsUpdateRequestStatus = null; },
    sendRequestModifierGroupsDelete: (state) => { state.modifierGroupsDeleteRequestStatus = 'pending'; },
    sendRequestModifierGroupsDeleteSuccess: (state) => { state.modifierGroupsDeleteRequestStatus = 'resolved'; },
    sendRequestModifierGroupsDeleteError: (state) => { state.modifierGroupsDeleteRequestStatus = 'rejected'; },
    clearModifierGroupsDeleteStatus: (state) => { state.modifierGroupsDeleteRequestStatus = null; },

    //Modifier Groups Products
    sendRequestModifierGroupsProductsGet: (state) => { state.modifierGroupsProductsGetRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductsGetSuccess: (state) => { state.modifierGroupsProductsGetRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductsGetError: (state) => { state.modifierGroupsProductsGetRequestStatus = 'rejected'; },
    clearModifierGroupsProductsGetStatus: (state) => { state.modifierGroupsProductsGetRequestStatus = null; },
    setModifierGroupsProducts: (state, action) => {
      state.modifierGroupsProducts = action.payload;
    },
    sendRequestModifierGroupsProductsCreate: (state) => { state.modifierGroupsProductCreateRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductsCreateSuccess: (state) => { state.modifierGroupsProductCreateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductsCreateError: (state) => { state.modifierGroupsProductCreateRequestStatus = 'rejected'; },
    clearModifierGroupsProductsCreateStatus: (state) => { state.modifierGroupsProductCreateRequestStatus = null; },
    sendRequestModifierGroupsProductUpdate: (state) => { state.modifierGroupsProductUpdateRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductUpdateSuccess: (state) => { state.modifierGroupsProductUpdateRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductUpdateError: (state) => { state.modifierGroupsProductUpdateRequestStatus = 'rejected'; },
    clearModifierGroupsProductUpdateStatus: (state) => { state.modifierGroupsProductUpdateRequestStatus = null; },
    sendRequestModifierGroupsProductDelete: (state) => { state.modifierGroupsProductDeleteRequestStatus = 'pending'; },
    sendRequestModifierGroupsProductDeleteSuccess: (state) => { state.modifierGroupsProductDeleteRequestStatus = 'resolved'; },
    sendRequestModifierGroupsProductDeleteError: (state) => { state.modifierGroupsProductDeleteRequestStatus = 'rejected'; },
    clearModifierGroupsProductDeleteStatus: (state) => { state.modifierGroupsProductDeleteRequestStatus = null; },
  },
});

export const {
  //Menu
  updateAllProducts,
  updateProducts,
  setCurrentRace,
  updateCurrentRace,

  //Modifier Groups
  sendRequestModifierGroupsGet,
  sendRequestModifierGroupsGetSuccess,
  sendRequestModifierGroupsGetError,
  clearModifierGroupsGetStatus,
  setModifierGroups,
  setCurrentModifierGroupId,
  sendRequestModifierGroupsCreate,
  sendRequestModifierGroupsCreateSuccess,
  sendRequestModifierGroupsCreateError,
  clearModifierGroupsCreateStatus,
  sendRequestModifierGroupsUpdate,
  sendRequestModifierGroupsUpdateSuccess,
  sendRequestModifierGroupsUpdateError,
  clearModifierGroupsUpdateStatus,
  sendRequestModifierGroupsDelete,
  sendRequestModifierGroupsDeleteSuccess,
  sendRequestModifierGroupsDeleteError,
  clearModifierGroupsDeleteStatus,

  //Modifier Groups Products
  sendRequestModifierGroupsProductsGet,
  sendRequestModifierGroupsProductsGetSuccess,
  sendRequestModifierGroupsProductsGetError,
  clearModifierGroupsProductsGetStatus,
  setModifierGroupsProducts,
  sendRequestModifierGroupsProductsCreate,
  sendRequestModifierGroupsProductsCreateSuccess,
  sendRequestModifierGroupsProductsCreateError,
  clearModifierGroupsProductsCreateStatus,
  sendRequestModifierGroupsProductUpdate,
  sendRequestModifierGroupsProductUpdateSuccess,
  sendRequestModifierGroupsProductUpdateError,
  clearModifierGroupsProductUpdateStatus,
  sendRequestModifierGroupsProductDelete,
  sendRequestModifierGroupsProductDeleteSuccess,
  sendRequestModifierGroupsProductDeleteError,
  clearModifierGroupsProductDeleteStatus,
} = kitchenMenuSlice.actions;

export default kitchenMenuSlice.reducer;
