import React, { useState, useEffect } from "react";
import { Typography, Table, Button, Form, Input, InputNumber, Tabs, Drawer, Flex, Select, DatePicker, Space, Switch, Slider, Row, Col, Tag } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Link } from "react-router-dom";
import PrintTable from "../../../../../components/PrintTable";
import { formatDate } from "../../../../../core/utils";
dayjs.extend(customParseFormat);

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const serverFormat = 'YYYY-MM-DD';
const clientFormat = 'DD-MM-YYYY';

const PromocodesDrawerComponent = ({ isDrawerOpen, onClose, record, kitchenOptions, categoriesOptions, onUpdatePromocode, transactions, totalTransactions, currentTransactionsPage, onSearchTransaction, onChange }) => {
  const [formUpdateWallet] = Form.useForm();
  const [formFilterTransaction] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [amount, setAmount] = useState([0, 100000]);

  useEffect(() => {
    if (record) {
      setIsFormChanged(false);

      const kitchenIds = record.kitchens.split(',').map(id => parseInt(id, 10));
      const initialKitchens = kitchenOptions.flatMap(group => 
        group.options.filter(option => kitchenIds.includes(option.value))
      );

      formUpdateWallet.setFieldsValue({
        promocode: record.promocode,
        message: record.message,
        discountPerc: record.discountPerc || 0,
        discountMax: Number(record.discountMax) || 0,
        maxCount: record.maxCount || 0,
        maxUsing: record.maxUsing || 0,
        OnlyForFirstOrder: record.OnlyForFirstOrder || 0,
        maxCountOrders: record.maxCountOrders || 0,
        orderMin: Number(record.orderMin) || 0,
        orderMax: Number(record.orderMax) || 0,
        cashbackPerc: record.cashbackPerc || 0,
        cashbackSum: Number(record.cashbackSum) || 0,
        valid: record.validFrom && record.validTo ? [dayjs(record.validFrom), dayjs(record.validTo)] : [],
        orderDays: record.orderDaysFrom && record.orderDaysTo ? [dayjs(record.orderDaysFrom), dayjs(record.orderDaysTo)] : [],
        status: record.status,
        kitchens: initialKitchens.length > 0 ? initialKitchens.map(option => option.value) : (record.kitchens.length > 0 ? record.kitchens.split(',').map(item => `ID: ${item}`) : []),
        categoryId: record.categoryId,
      });
    }
  }, [record, formUpdateWallet]);

  const handleCloseDrawer = () => { onClose(false); };
  const handleFormChange = () => { setIsFormChanged(true); };

  const handleUpdatePromocodes = () => {
    formUpdateWallet.validateFields()
    .then((values) => {
      if (!values.categoryId) {
        delete values.categoryId;
      }

      if (values.valid !== null && values.valid.length) {
        const [validFrom, validTo] = values.valid;
        values.validFrom = validFrom.format(serverFormat);
        values.validTo = validTo.format(serverFormat);
        delete values.valid;
      } else {
        delete values.valid;
      }
    
      if (values.orderDays !== null && values.orderDays.length) {
        const [orderDaysFrom, orderDaysTo] = values.orderDays;
        values.orderDaysFrom = orderDaysFrom.format(serverFormat);
        values.orderDaysTo = orderDaysTo.format(serverFormat);
        delete values.orderDays;
      } else {
        delete values.orderDays;
      }
  
      if (values.kitchens.length) {
        values.kitchens = values.kitchens.join();
      } else {
        delete values.kitchens;
      }

      console.log({
        ...values,
        promocodId: record.id,
        walletName: record?.wallet?.name,
        walletAmount: record?.wallet?.amount,
      });

      onUpdatePromocode({
        ...values,
        promocodId: record.id,
        walletName: record?.wallet?.name,
        walletAmount: record?.wallet?.amount,
      });
    })
    .catch((errorInfo) => {
      console.error('Ошибка при валидации формы:', errorInfo);
    });
  };

  const onMinInputChange = (value) => { setAmount([value, amount[1]]); };
  const onMaxInputChange = (value) => { setAmount([amount[0], value]); };

  const handleSearchTransaction = (values) => {
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([_, v]) => v !== undefined && v !== null && v !== '')
    );
  
    if (amount) {
      const [amountFrom, amountTo] = amount;
      filteredValues.amountFrom = amountFrom;
      filteredValues.amountTo = amountTo;
      delete filteredValues.amount;
    }
  
    if (filteredValues.dateRange) {
      const [dateRangeFrom, dateRangeTo] = filteredValues.dateRange;
      filteredValues.dateRangeFrom = dateRangeFrom.format(serverFormat);
      filteredValues.dateRangeTo = dateRangeTo.format(serverFormat);
      delete filteredValues.dateRange;
    }

    onSearchTransaction({...filteredValues, walletId: record.wallet.id});
  }

  const handleTableChange = (pagination) => {
    onChange(pagination.current);
  };

  return (
    <Drawer
      title={
        <Flex gap={20}>
          {isFormChanged &&
            <>
              <Button type="primary" onClick={handleUpdatePromocodes}>Сохранить</Button>
              <Button onClick={handleCloseDrawer}>Отменить</Button>
            </>
          }
        </Flex>
      }
      placement="right"
      mask={false}
      width='40%'
      open={isDrawerOpen}
      onClose={handleCloseDrawer}
    >
      {record && 
      <>
        <Tabs defaultActiveKey="1" size="small">
          <TabPane tab="Параметры" key="1">
            <Form
              form={formUpdateWallet}
              layout='horizontal'
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              labelAlign='left'
              onValuesChange={handleFormChange}
            >
              <Form.Item style={{ margin: '5px', flex: 1 }} name="promocode" label="Название" rules={[{ required: true, message: 'Обязательное поле' }]}>
                <Input style={{ width: '100%' }} placeholder="Введите код промокода" />
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="message" label="Описание промокода">
                <Input.TextArea style={{ width: '100%' }} rows={3} placeholder="Введите описание промокода" />
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1}} name="discountPerc" label="Процент скидки" rules={[{ required: true, message: 'Обязательное поле' }]}>
                <InputNumber style={{ width: '100%' }} min={0} max={100} formatter={value => `${value}%`}/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="discountMax" label="Максимальная скидка">
                <InputNumber style={{ width: '100%' }} min={0} formatter={value => `${value} ₽`}/>
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="maxCount" label="Макс. кол-во">
                <InputNumber style={{ width: '100%' }} min={0}/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="maxUsing" label="Макс. кол-во использований">
                <InputNumber style={{ width: '100%' }} min={0}/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="OnlyForFirstOrder" label="Макс. использований клиентом">
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="maxCountOrders" label="Макс. кол-во уже сделанных заказов">
                <InputNumber style={{ width: '100%' }} min={0} />
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="orderMin" label="Минимальная сумма заказа">
                <InputNumber style={{ width: '100%' }} min={0} formatter={value => `${value} ₽`}/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="orderMax" label="Максимальная сумма заказа">
                <InputNumber style={{ width: '100%' }} min={0} formatter={value => `${value} ₽`}/>
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="cashbackPerc" label="Процент кэшбэка">
                <InputNumber style={{ width: '100%' }} min={0} formatter={value => `${value} %`}/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="cashbackSum" label="Сумма кэшбэка за примененный промокод">
                <InputNumber style={{ width: '100%' }} min={0} formatter={value => `${value} ₽`}/>
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="valid" label="Время действия промокода">
                <RangePicker style={{ width: '100%' }} format={clientFormat}/>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="orderDays" label="Действует на дни">
                <RangePicker style={{ width: '100%' }} format={clientFormat}/>
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="walletName" label="Кошелек">
                <Link to={`/finance/wallets?walletId=${record?.wallet?.id}`} target='_blank'>{record?.wallet?.name}</Link>
              </Form.Item>
              <Form.Item style={{ margin: '5px', flex: 1 }} name="walletAmount" label="Сумма расходования">
                {record?.wallet?.amount ? `${record?.wallet?.amount} баллов` : ''}
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="status" label="Промокод активен" valuePropName="checked">
                <Switch/>
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="kitchens" label="Действует в кухнях">
                <Select
                  style={{ width: '100%' }}
                  mode="multiple"
                  showSearch
                  placeholder="Выберите кухни"
                  optionFilterProp="children"
                  options={kitchenOptions}
                  filterOption={(input, option) =>
                    (option?.kitchenId?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.inn?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionRender={(option) => (
                    <Flex vertical >
                      <Title level={5}>{option.data.name}</Title>
                      <Flex gap='small'>
                        <Text type="secondary" size="small">ID: {option.data.id}</Text>
                        <Text type="secondary" size="small">{option.data.cityName}</Text>
                      </Flex>
                    </Flex>
                  )}
                />
              </Form.Item>

              <Form.Item style={{ margin: '5px', flex: 1 }} name="categoryId" label="Действует в категориях">
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  placeholder="Выберите категорию"
                  optionFilterProp="children"
                  options={categoriesOptions}
                  filterOption={(input, option) =>
                    (option?.categoryId?.toString() ?? '').toLowerCase().includes(input.toLowerCase()) ||
                    (option?.name?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  optionRender={(option) => (
                    <Flex vertical >
                      <Title level={5}>{option.data.name}</Title>
                    </Flex>
                  )}
                />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Транзакции" key="2">
            <Form
              form={formFilterTransaction}
              layout='vertical'
              onFinish={values => handleSearchTransaction(values)}
              style={{ marginBottom: 16 }}
            >
              <Flex gap={16}>
                <Form.Item style={{ flex: '4', margin: '5px' }} name="amount" label="Диапазон сумм">
                  <Flex gap={10}>
                    <InputNumber
                      min={0}
                      max={1000}
                      value={amount[0]}
                      onChange={onMinInputChange}
                      style={{ width: '100%' }}
                    /> - 
                    <InputNumber
                      min={0}
                      max={100000}
                      value={amount[1]}
                      onChange={onMaxInputChange}
                      style={{ width: '100%' }}
                    />
                  </Flex>
                </Form.Item>
                <Form.Item style={{flex: '6', margin: '5px'}} name="dateRange" label="Создание транзакции">
                  <RangePicker style={{width: '100%'}} format={clientFormat}/>
                </Form.Item>
              </Flex>
              <Flex gap={16}>
                <Form.Item style={{flex: '4', margin: '5px'}} name="orderNumber" label="Номер заказа">
                  <Input placeholder="Введите номер заказа" />
                </Form.Item>
                <Form.Item style={{flex: '6', margin: '5px'}} name="comment" label="Комментарий">
                  <Input placeholder="По комментарию" />
                </Form.Item>
              </Flex>
              <Flex style={{marginTop: '20px'}} justify='space-between' gap={16}>
                <Text type='secondary'>Найдено транзакций: {new Intl.NumberFormat().format(totalTransactions)}</Text>
                <Flex gap={16}>
                  <Button type='primary' htmlType='submit'>Поиск</Button>
                  <Button htmlType='reset'>Сбросить</Button>
                </Flex>
              </Flex>
            </Form>
            <PrintTable>
              <Table
                size='small'
                dataSource={transactions}
                columns={[
                  {
                    title: 'Дата и время', 
                    key: 'created',
                    render: (record) => (<Text>{formatDate(record.created)}</Text>),
                  },
                  {
                    title: 'Сумма', 
                    key: 'amount',
                    render: (record) => (
                      <Text style={{color: record.walletFrom ? '#ff0000' : record.walletTo ? '#249224' : undefined}}>{record.walletFrom ? '-' : record.walletTo ? '+' : undefined}{record.amount}</Text>
                    ),
                  },
                  {
                    title: 'Комментарий', 
                    dataIndex: 'comment', 
                    key: 'comment'
                  },
                ]}
                pagination={{
                  current: currentTransactionsPage,
                  total: totalTransactions,
                  pageSize: 20,
                  showSizeChanger: false,
                  showQuickJumper: true,
                }}
                onChange={handleTableChange}
              />
            </PrintTable>
          </TabPane>
        </Tabs>
      </>
      }
    </Drawer>
  );
};

export default PromocodesDrawerComponent;
