import React from 'react';
import { CardSection } from '../../../../../core/styles';
import { TreeangleWarningIcon } from '../../../../../components/Icons';
import Text from '../../../../../ui/Text';
import styled from 'styled-components';
import Card from '../../../../../components/Card';

const MenuStats = ({ stats }) => {
    const { totalProducts, withoutPhoto, withoutKBJU, noBread, noCutlery } = stats;

    return (
        <Wrapper>
            <CardSection>
                <CardSectionHeader>
                    <Text MenuStatsHeadersize='24px'>Товаров в меню</Text>
                    <Text strong size='24px'>{totalProducts}</Text>
                </CardSectionHeader>
                {(withoutPhoto > 0 || withoutKBJU > 0) &&
                    <CardSectionList>
                        {withoutPhoto > 0 &&
                            <CardSectionItem>
                                <CardSectionCount>
                                    <p>Без фото</p><span>{withoutPhoto}</span>
                                </CardSectionCount>
                                <CardSectionInfo>
                                    <div><TreeangleWarningIcon/></div>
                                    <p>Товары без фото продаются на 40-60% хуже</p>
                                </CardSectionInfo>
                            </CardSectionItem>
                        }
                        {withoutKBJU > 0 &&
                            <CardSectionItem>
                                <CardSectionCount>
                                    <p>Без КБЖУ</p><span>{withoutKBJU}</span>
                                </CardSectionCount>
                                <CardSectionInfo>
                                    <div><TreeangleWarningIcon/></div>
                                    <p>Для части пользователей наличие данных КБЖУ критично при формировании заказа</p>
                                </CardSectionInfo>
                            </CardSectionItem>
                        }
                    </CardSectionList>
                }
            </CardSection>

            {(noBread > 0 || noCutlery > 0) && 
                <CardSection>
                    <CardSectionHeader>
                        <Text size='24px' color='#EA394B'>Отсутсвуют в меню</Text>
                    </CardSectionHeader>
                    <CardSectionList>
                        {noBread > 0 && <Text size='18px' color='#EA394B'>Хлеб</Text>}
                        {noCutlery > 0 && <Text size='18px' color='#EA394B'>Приборы</Text>}
                    </CardSectionList>
                </CardSection>
            }
        </Wrapper>
    );
};

export default MenuStats;

const Wrapper = styled(Card)`
    flex: 0 0 auto;
    gap: 12px;
`
const CardSectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`

const CardSectionList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    padding-top: 24px;
    border-top: solid 1px #eeeeee;
`
const CardSectionItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
const CardSectionCount = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    font-size: 18px;
`
const CardSectionInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    font-size: 14px;
    color: #858080;
`