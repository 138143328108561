import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from "styled-components";
import Text from '../../../../../ui/Text';
import Card from '../../../../../components/Card';
import Title from '../../../../../ui/Title';
import { formatDate } from '../../../../../core/utils';
import PrintTable from '../../../../../components/PrintTable';
import { PlusIcon } from '../../../../../components/Icons';
import Tooltip from '../../../../../ui/Tooltip';

const Menu = ({ isBlocked, currentRace, timeStart, timeEnd, products, onUpdateProduct }) => {
    const { isMobile } = useSelector((store) => store.common);
    const [groupedProducts, setGroupedProducts] = useState({});

    useEffect(() => {
        const categories = products.filter(products => products.status === 'on').reduce((acc, product) => {
            const { categoryName } = product;
            if (!acc[categoryName]) {
                acc[categoryName] = [];
            }
            acc[categoryName].push(product);
            return acc;
        }, {});
        setGroupedProducts(categories);
    }, [products]);

    const handleUpdateProduct = (product) => {
        const updatedAllProducts = products.map((item) => {
            return item.productId === product.productId 
                ? { ...item, ...product }
                : item;
        });

        onUpdateProduct(updatedAllProducts, product)
    }

    return (
        <PrintTable>
        <Card style={{filter: isBlocked && 'opacity(0.6)'}}>
            {isBlocked && <BlockedLayer>
                <div>Приём заказов<br/>приостановлен</div>
            </BlockedLayer>}
            <CardHeader isMobile={isMobile}>
                <div>
                    <Title level={isMobile ? 4 : 2} style={{marginBottom: isMobile ? '8px' : '16px'}}>Меню "{currentRace?.kitchenName}" на {currentRace?.deliveryDay && formatDate(currentRace?.deliveryDay)}</Title>
                    <Text size={isMobile ? '16px' : '24px'}>доставка с {timeStart}-{timeEnd}</Text>
                </div>
                {/* <div>
                    123
                </div> */}
            </CardHeader>

            {Object.entries(groupedProducts).map(([categoryName, items], index) => (
                <Category key={categoryName}>
                    <Title level={isMobile ? 4 : 3}>{categoryName}</Title>
                    <StyledTable>
                        {index === 0 && (
                            <thead>
                                <StyledTr>
                                    <StyledTh isMobile={isMobile} style={{ width: '100%' }}>Название</StyledTh>
                                    <StyledTh isMobile={isMobile}>Цена</StyledTh>
                                    <StyledTh isMobile={isMobile}>{isMobile? 'пр-но' : 'Продано'}</StyledTh>
                                    <StyledTh isMobile={isMobile}>{isMobile? 'опуб-но' : 'Опубликовано'}</StyledTh>
                                </StyledTr>
                            </thead>
                        )}
                        <tbody>
                            {items.map(item => (
                                <StyledTr key={item.productId}>
                                    <StyledTd isMobile={isMobile} style={{width: '100%'}}>{item.name}</StyledTd>
                                    <StyledTd isMobile={isMobile}>{item.price}</StyledTd>
                                    <StyledTd isMobile={isMobile}>{item.amountOrdered || '0'}</StyledTd>
                                    <StyledTd isMobile={isMobile}>
                                        <OrderedCount>
                                            {item.amountForOrder || '∞'}

                                            {item.amountForOrder !== 0 &&
                                            item.amountOrdered === item.amountForOrder &&
                                                <IncrementButton item={item} onClick={handleUpdateProduct}/>
                                            }
                                        </OrderedCount>
                                    </StyledTd>
                                </StyledTr>
                            ))}
                        </tbody>
                    </StyledTable>
                </Category>
            ))}
        </Card>
        </PrintTable>
    );
};

export default Menu;

const IncrementButton = ({ item, onClick, increments = [1, 2, 3, 4, 5] }) => {
    const handleIncrement = (value) => {
        const updatedItem = {
            ...item,
            amountForOrder: (+item.amountForOrder || 0) + value,
        };
        onClick(updatedItem);
    };

    return (
        <Tooltip
            title='Сколько товаров добавить?'
            text={
                <IncrementOptions>
                    {increments.map((increment) => (
                        <IncrementOption
                            key={increment}
                            onClick={() => handleIncrement(increment)}
                        >
                            +{increment}
                        </IncrementOption>
                    ))}
                </IncrementOptions>
            }
            trigger="click"
            placement="top"
        >
            <StyledButton><PlusIcon height='16px'/></StyledButton>
        </Tooltip>
    );
};

const BlockedLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    div {
        margin-top: 30vh;
        width: 320px;
        height: fit-content;
        padding: 20px;
        background: #EDEDED;
        border-radius: 24px;

        font-size: 32px;
        font-weight: 500;
        text-align: center;
        color: #B2B2B2;
    }
`;

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${({isMobile}) => isMobile ? '8px' : '16px'};
    margin-bottom: ${({isMobile}) => isMobile ? '32px' : '48px'};
`;

const Category = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 0;
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
`;

const StyledTr = styled.tr`
    &:not(:last-child) {
        border-bottom: 1px solid #eeeeee;
    }
`;

const StyledTh = styled.th`
    min-width: ${({isMobile}) => isMobile ? '50px' : '90px'};
    padding: 16px 5px;
    font-size: ${({isMobile}) => isMobile ? '10px' : '14px'};
    color: #b2b2b2;
    text-align: left;
    &:last-child {
        min-width: ${({isMobile}) => isMobile ? '50px' : '110px'};
    }
`;

const StyledTd = styled.td`
    min-width: ${({isMobile}) => isMobile ? '50px' : '90px'};
    padding: 12px 5px;
    font-size: ${({isMobile}) => isMobile ? '14px' : '18px'};
    text-align: left;
    &:last-child {
        min-width: ${({isMobile}) => isMobile ? '50px' : '110px'};
    }
`;

const OrderedCount  = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

const IncrementOptions = styled.div`
    display: flex;
    gap: 8px;
`;

const IncrementOption = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: #e6f7ff;
    }
`;

const StyledButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    height: 24px;
    background: #EA394B;
    border: none;
    border-radius: 24px;
    font-size: 14px;
    color: #ffffff;
    transition: all .3s;
    cursor: pointer;
`;
